<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
#app {
  min-height: 100vh;
}
.el-message {
  border-radius: 0;
}
.footer {
  text-align: center;
  font-size: 14px;
  margin: 10px 0;
}
.app-footer {
  font-size: 14px;
  text-align: center;
  padding: 20px 0;
}
</style>
