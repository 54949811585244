import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { request } from './api'

import "@/icons";
import "./styles/index.scss";

import "element-ui/lib/theme-chalk/index.css";
import ElementUI from "element-ui";

Vue.use(ElementUI);

Vue.config.productionTip = false;
/**
 * @description 全局注册请求变量
 */
Vue.prototype.$request = request

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
